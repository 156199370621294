import { StatementsList } from './StatementsList'
import { FirebaseContext } from '/domain/FirebaseContext'

export default function StatementsListApp() {
  return (
    <FirebaseContext>
      <StatementsList />
    </FirebaseContext>
  )
}
