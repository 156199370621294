import { Loader } from '/features/buildingBlocks/Loader'
import { statements } from '/data/statements'
import { useFirebaseValue } from '/machinery/useFirebaseDatabase'
import { ref, equalTo, orderByChild, query } from 'firebase/database'
import { MiniCard, MiniCardList } from '/features/buildingBlocks/MiniCard'

export function StatementsList({ layoutClassName = undefined }) {
  const { isFetching, data } = useStatementsList()

  if (isFetching) return <Loader />

  return (
    <MiniCardList {...{ layoutClassName }}>
      {Object.values(data).map(statement => (
        <MiniCard key={statement.id}>
          {statement.statement}
        </MiniCard>
      ))}
    </MiniCardList>
  )
}

function useStatementsList() {
  const { data, isFetching } = useFirebaseValue({
    reference: ({ user, database }) => query(
      ref(database, `/users/${user.uid}/statements`),
      orderByChild('selected'),
      equalTo(true)
    ),
  })

  return {
    isFetching,
    data: data ? mapStementsToTheSelectedEntries(data) : {},
  }

  function mapStementsToTheSelectedEntries(data) {
    return Object.keys(data).reduce(
      (result, key) => {
        result[key] = statements[key]
        return result
      },
      {}
    )
  }
}
